import dayjs, { Dayjs as _Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import calendar from "dayjs/plugin/calendar";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import isoWeek from "dayjs/plugin/isoWeek";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(localizedFormat);
dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);

dayjs.extend(function until(o, c) {
  c.prototype.until = function (timeZone: string) {
    const now = dayjs().tz(timeZone);
    let output = this.fromNow();
    const isSameWeek = now.isSame(this, "isoWeek");
    if (isSameWeek) {
      output = "This week";
    } else {
      const diffWeek = this.isoWeek() - now.isoWeek();
      if (diffWeek < 0) {
        if (diffWeek === -1) {
          output = "Last week";
        } else if (diffWeek === -2) {
          output = "2 weeks ago";
        }
      } else {
        if (diffWeek === 1) {
          output = "Next week";
        } else if (diffWeek < 4) {
          output = `in ${diffWeek} weeks`;
        } else {
          const diffMonth = this.month() - now.month();
          if (diffMonth === 1) {
            output = `in 1 month`;
          } else {
            output = `in ${diffMonth} months`;
          }
        }
      }
    }
    return output;
  };
});

declare module "dayjs" {
  interface Dayjs {
    until(timeZone: string): string;
  }
}

export type Dayjs = _Dayjs;
export { dayjs };
